import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PageBox from '../components/page-box'


const PaginaSnodo = ({ location, data }) => {
  const { page } = data;
  const { seo, heroDesktop, heroMobile, pagine } = page;
  const { titolo: title, titoloInPagina: subtitle } = page;
  const breadcrumb = [{ title: 'Home', link: '/'}, { title: page.titolo }];
  

  return (
    <Layout location={location} hero={{ title, subtitle,  heroDesktop, heroMobile, breadcrumb, className: 'hero--small hero--overlay' }}>
      <SEO title={title} seo={seo} image={heroDesktop} />

      <div className="section section--gray"> 
        <div className="container">
            <div className="row">
              {pagine.map((item,index) =>
                <div key={index} className="col-12 col-lg-4">
                  {!!item.boxLancio ? (
                    <PageBox snodo={title} page={item.titolo} data={item.boxLancio}/>
                  ) :(
                    <h3>Manca Box lancio pagina {item.titolo}</h3>
                  )}
                </div>
              )}
            </div>
        </div>
      </div>
      
    </Layout>
  );
}

export default PaginaSnodo;
export const query = graphql`query PaginaSnodoQuery($contentful_id: String!){
  page: contentfulPaginaSnodo(contentful_id: { eq: $contentful_id}) {
    titolo
    titoloInPagina
    seo {
      ...SeoFragment
    }
    heroDesktop: immagineHero {
      ...HeroContentfulDesktop
    }
    heroMobile {
      ...HeroContentfulMobile
    }
    pagine {
      titolo
      boxLancio {
        icona {
          gatsbyImageData(layout: FIXED, width: 80, quality: 100)
        }
        titolo
        sommario {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
}`